import {$, hide, show} from "../../core/js/Dom.ts";
import {createModale, showModal} from "../../core/js/Modal.ts";
import {SelecteurDeServices} from "../../core/js/SelecteurDeServices.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url === 'inscription_agent') {
        $('#btn-modal-selecteur')?.addEventListener('click', showSelectionServiceModale);
    }
})


function showSelectionServiceModale(event: DOMEvent<HTMLElement>) {
    event.preventDefault()
    const modale = createModale('demandeAvisModal', {
        title: window.Openscop.selecteur.selection.modal_title,
    })
    if (modale) {
        show($<HTMLElement>('#consult_div', modale))
        show($<HTMLElement>('#modal-instance-selecteur', modale))
        hide($<HTMLElement>('#modal-categorie-selecteur', modale))
        showModal('demandeAvisModal', {
            disableScroll: true,
        })
        new SelecteurDeServices({
            ...window.Openscop.selecteur.selection,
        })
    }
}