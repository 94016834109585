import {$get} from "../../core/js/Ajax.ts";
import {$, $$} from "../../core/js/Dom.ts";
import {switchTab} from "../../core/js/SwitchTab.ts";

document.addEventListener('DOMContentLoaded', () => {
    if(window.Openscop.url_get_alert_bloc) refreshAlerts()
})


function refreshAlerts() {
    $get(window.Openscop.url_get_alert_bloc).then((response) => {
        const parent = $<HTMLElement>('#openscop-alertes')
        parent!.innerHTML = response.body
        $$('[data-trigger="tab"]', parent!).forEach(el => el.addEventListener('click', switchTab))
    })
}